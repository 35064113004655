export const flavour = {
  // User Management specific endpoints
  modUM: {
    getUserList: "getListUsersSecured",
    getFilteredUserList: "getUsersInGroupSecured",
    getDepartments: "getAllDepartmentsSecured",
    getClientDepartments: "getClientDepartmentsSecured",
    getMergedDepartments: "getMergedDepartmentsSecured",
    getModules: "getClientModulesSecured",
    getStatus: "getStatusListSecured",
    createUser: "addUserSecured",
    inviteUser: "inviteUserSecured",
    getUserDetails: "getUserDetailsSecured",
    rejectUser: "rejectSentinelUserSecured",
    disableUser: "disableSentinelUserSecured",
    enableUser: "enableSentinelUserSecured",
    deleteUser: "deleteSentinelUserSecured",
    approveUser: "approveSentinelUserSecured",
    updateUser: "updateUserSecured",
    getAllRoles: "getClientRolesSecured",
    updateAgent: "updateAgentSecured",
    getUsersInGroup: "getUsersInGroupSecured",
    getDomains: "getAllDomainsSecured",
    getAllPlatformRoles: "retrievePlatformRoles",
    getSentinelUser: "sentinelUsers",
    getSentinelDetails: "getUserDetails",
    createSentinelUser: "createSentinelUserSecured",
    updateSentinelUser: "updateSentinelUserSecured",
    getClientAgents: "getClientAgentsSecured",
    resetUserPassword: "resetPwdSentinelUserSecured"
  },
  modCL: {
    getClientList: "getAllClientsSecured",
    getClientDetail: "getClientSecured",
    createClient: "createClientSecured",
    updateClient: "updateClientSecured",
    transitionClientStatus: "transitionClientStatusSecured",
    getModules: "getAllModulesSecured",
    getChannels: "getAllChannelsSecured",
    getCountryCodes: "getCountryCodesSecured",
    getCurrencies: "getCurrencySecured",
    getLanguages: "getLanguagesSecured",
    getAllTimezones: "getAllTimezonesSecured",
    getDepartments: "getAllDepartmentsSecured",
    getRestrictionPolicy: "getRestrictionPolicy",
    saveRestrictionPolicy: "saveRestrictionPolicy",
    enableDisable: "updateClientSecured",
    deleteClient: "deleteSecured",
    deleteDeptApiCall: "deleteDepartmentMappingSecured",
    getHostList: "getAllPlatformsSecured",
    createDepartemnt: "createDepartmentSecured",
    getUserList: "getListUsersSecured",
    getDomains: "getAllDomainsSecured",
    getAdminUsers: "getAdminUsersSecured",
    getClientDomainDirs: "getClientDomainDirsSecured",
    addClientDomainDirs: "addClientDomainDirsSecured",
    deleteClientDomainDirs: "deleteClientDomainDirsSecured",
    getClientDataRetention: "getClientDataRetentionSecured",
    getClientMetaData: "getClientMetaDataSecured",
    getAllFromEmails: "listClientFromEmailsSecured",
    getClientAgents: "getClientAgentsSecured",
    searchClients: "searchClientsSecured",
    googleAuthURL: "googleAuthURL",
    msAuthURL: "msAuthURL",
    zohoConsentURL: "zohoConsentURL",
    createCMSUser: "createCMSUserSecured",
    updateCMSUser: "updateCMSUserSecured",
    inviteCMSUser: "inviteCMSUserSecured",
    getCMSUserDetails: "getCMSUserDetailsSecured",
    disableCMSUser: "disableCMSUserSecured",
    enableCMSUser: "enableCMSUserSecured",
    approveCMSUser: "approveCMSUserSecured",
    rejectCMSUser: "rejectCMSUserSecured",
    deleteCMSUser: "deleteCMSUserSecured",
    getUserDepartments: "getUserDepartmentsSecured"
  },
  modLC: {
    getAgentList: "getAgentsListSecured",
    listAllAgents: "listAgentsSecured",
    getActiveListing: "agentWorkloadSecured",
    getAgentStatus: "agentStatisticsSecured",
    getAgentsDepartments: "listAgentsSecured",
    changeAgentStatus: "updateStatusSecured",
    getAiAssistanceResponse: "getResponseSecured",
    getManualResponseFromAi: "getManualResponseSecured"
  },
  modTRG: {
    getAllTriggers: "alltriggersSecured",
    createTrigger: "triggerSecured",
    updateTrigger: "triggerSecured"
  },
  // ws
  modRTMCL: {
    connectRTM: "connectRTMSecured",
    connectAgentRTM: "connectAgentRTMSecured"
  },
  modJB: {
    getJourneyList: "viewJBSecured",
    getWorkflowList: "getWorkflowIdsSecured",
    getSelectedJourney: "viewJbByIdSecured",
    getIntents: "getIntentListSecured",
    getBehaviours: "getBehavioursSecured",
    getNLPEngines: "getNlpEnginesSecured",
    getDepartments: "getAllDepartmentsSecured",
    createJourney: "createJBSecured",
    updateJourney: "updateJBSecured",
    uploadJourney: "uploadJBSecured",
    duplicateJourney: "copyJBSecured",
    updateStatus: "updateStatusSecured",
    deleteJourney: "deleteJBSecured",
    getSections: "getSectionSecured",
    createSection: "saveSectionSecured",
    getMilestones: "getMilestoneSecured",
    createMilestone: "saveMilestoneSecured",
    getStackList: "getStackListSecured",
    viewJbById: "viewJbByIdSecured",
    fetchAllWorkflows: "fetchAllWorkflowsSecured",
    getClientWorkflows: "getClientWorkflowsSecured"
  },
  modCONVMGMT: {
    getBehaviorListApiCall: "getBehavioursSecured",
    deleteBehaviour: "deleteBehaviourSecured",
    disableApiCall: "changeBehaviourSecured",
    copyApiCall: "copyBehaviourSecured",
    createBehaviour: "saveBehaviourSecured",
    makeBehaviourTemplate: "makeBehaviourTemplateSecured",
    saveSEOFile: "saveSEOFileSecured",
    updateSEOFile: "updateSEOFileSecured",
    publishSEOFile: "publishSEOFileSecured",
    getSEOFileInfo: "getSEOFileInfoSecured",
    uploadFile: "uploadFileSecured",
    getBehaviourDetail: "getBehaviourByIdSecured",
    getNlpEngines: "getNlpEnginesForBehaviourSecured",
    getNlpListApiCall: "getNlpEnginesSecured",
    getNlpDetails: "getNlpEngineDetailSecured",
    saveNlpEngine: "saveNlpEngineSecured",
    updateNLPAsset: "updateNLPAssetSecured",
    updateNlpEQPrompt: "updateNlpEQPromptSecured",
    getIntentList: "getIntentsSecured",
    getIntentsByBehavior: "getIntentsByBehaviorSecured",
    approveNLP: "approveNlpEngineSecured",
    rejectNLP: "rejectNlpEngineSecured",
    changeNlpStatus: "updateNlpStatusSecured",
    getIntentDetails: "getIntentDetailsSecured",
    getSentimentDetails: "getSentimentDetailsSecured",
    createSentiment: "saveResponseSecured",
    getBehaviourNlpEngine: "getNlpEnginesForBehaviourSecured",
    getNlpByBehavior: "getNlpByBehaviorSecured",
    getBehaviourDomains: "listClientDomainsSecured",
    linkedBehaviours: "linkedBehavioursSecured",
    fetchAllBehaviours: "fetchAllBehavioursSecured",
    listAllowedBehaviours: "listAllowedBehavioursSecured",
    fetchAllNlpEngines: "fetchAllNlpEnginesSecured",
    updateNlpEngine: "updateNlpEngineSecured",
    updateNLPProperties: "updateNLPPropertiesSecured",
    updateNlpSettings: "updateNlpSettingsSecured",
    getNlpEnginesLight: "getNlpEnginesLightSecured",
    getMultiPageByDomainDirectory: "getMultiPageByDomainDirectorySecured",
    updateCommonPage: "updateCommonPageSecured"
  },
  modINT: {
    getIntentList: "getIntentListSecured",
    getIntentDetails: "getIntentDetailsSecured",
    saveIntent: "saveIntentSecured",
    approveIntent: "approveIntentSecured",
    approveResponse: "approveResponseSecured",
    checkDuplicateUtterance: "checkDuplicateUtteranceSecured",
    changeIntentStatus: "changeIntentStatusSecured",
    rejectIntent: "rejectIntentSecured",
    searchUtterance: "searchUtteranceSecured",
    getAllClientIntents: "getAllIntentsSecured"
  },
  modADT: {
    getAuditLogs: "getAuditLogsSecured",
    getAuditDetails: "getAuditLogDetailsSecured",
    getUserList: "getListUsersSecured",
    getModuleList: "getClientModulesSecured",
    fetchAuditEntries: "fetchAuditEntriesSecured",
    getClientAgents: "getClientAgentsSecured"
  },
  modANTS: {
    getWorkflow: "workflowSecured",
    getLivechat: "livechatSecured",
    getAI: "aiSecured",
    getForms: "formsSecured",
    getConsolidatedMetadata: "consolidatedMetadataSecured",
    getWebpage: "webpageSecured",
    getEvent: "eventSecured"
  },
  modAPIDef: {
    externalApiDefinitions: "externalApiDefinitionsSecured",
    allDefinitions: "allDefinitionsSecured",
    definitionDetails: "definitionDetailsSecured",
    updateApiDefinition: "updateApiDefinitionSecured",
    deleteApiDefinition: "deleteApiDefinitionSecured",
    apiDefinition: "apiDefinitionSecured",
    enableDefinition: "enableDefinitionSecured",
    disableDefinition: "disableDefinitionSecured",
    fetchAllDefinitions: "fetchAllDefinitionsSecured",
    listAllowedApiDefinition: "listAllowedApiDefinitionSecured"
  },
  modET: {
    allentities: "allentities",
    triggersOwned: "triggersOwnedSecured",
    retrieveTrigger: "retrieveTriggerSecured",
    trigger: "triggerSecured",
    makeTriggerTemplate: "makeTriggerTemplateSecured",
    updateTrigger: "updateTriggerSecured",
    getAllTriggerTypes: "getAllTriggerTypesSecured",
    deleteTrigger: "deleteTriggerSecured",
    listMasterTriggers: "listMasterTriggersSecured",
    enableTrigger: "enableTriggerSecured",
    disableTrigger: "disableTriggerSecured",
    addEntity: "addEntitySecured",
    updateEntity: "updateEntitySecured",
    deleteEntity: "deleteEntitySecured",
    landingPageTriggers: "landingPageTriggersSecured",
    fetchAllTriggersOwned: "fetchAllTriggersOwnedSecured",
    triggersOwnedByClient: "triggersOwnedByClientSecured",
    fetchResolvedTriggerInfo: "fetchResolvedTriggerInfoSecured",
    listAllowedTriggers: "listAllowedTriggersSecured",
    fetchAllEntities: "fetchAllEntitiesSecured"
  },
  modML: {
    getPinnedUtterances: "getPinnedUtterancesSecured",
    getUnhandledUtterances: "getUnhandledUtterancesSecured",
    updateUnhandledUtterances: "updateUnhandledUtterancesSecured",
    deleteUnhandledUtterances: "deleteUnhandledUtterancesSecured",
    assignUnhandledUtterances: "assignUnhandledUtterancesSecured",
    deletePinnedUtterances: "deletePinnedUtterancesSecured",
    updatePinnedUtterances: "updatePinnedUtterancesSecured",
    assignPinnedUtterances: "assignPinnedUtterancesSecured",
    delMulUnhandledUtterances: "delMulUnhandledUtterancesSecured",
    delMulPinnedUtterances: "delMulPinnedUtterancesSecured",
    getBehavioursByIntent: "getBehavioursByIntentSecured"
  },
  modAH: {
    getAssetList: "listAssetSecured",
    listAssetsUnsafe: "listAssetDetailsSecured",
    createAssetList: "createAssetSecured",
    createAssetFromCloud: "createAssetFromCloudSecured",
    updateAssetList: "updateAssetSecured",
    updateAssetFromCloud: "updateAssetFromCloudSecured",
    deleteAssetList: "deleteAssetSecured",
    disableAsset: "disableAssetSecured",
    enableAsset: "enableAssetSecured",
    fetchAllAssets: "fetchAllAssetsSecured",
    listLibraryAssets: "listLibraryAssetsSecured",
    listUnsplashImages: "listUnsplashImagesSecured",
    uploadFileFromCloud: "uploadFileFromCloudSecured",
    retrieveAsset: "retrieveAssetSecured"
  },
  modRTMC: {
    listActiveSessions: "listActiveSessionsSecured",
    agentStatistics: "agentStatisticsSecured",
    agentWorkload: "agentWorkloadSecured",
    listAgents: "listAgentsSecured",
    listActiveChannels: "listActiveChannelsSecured"
  },
  modCPM: {
    retrievePoliciesByType: "retrievePoliciesByTypeSecured",
    listPolicies: "listPoliciesSecured",
    getManagedElements: "getManagedElementsSecured",
    getManagedMethods: "getManagedMethodsSecured",
    createPolicy: "createPolicySecured",
    retrieveCMSPolicies: "retrieveCMSPoliciesSecured",
    getPolicyDetails: "getPolicyDetailsSecured",
    updatePolicy: "updatePolicySecured",
    resetCMSUserPassword: "resetCMSUserPasswordSecured"
  },
  modSESSION: {
    searchSolrSessions: "searchSolrSessionsSecured",
    retrieveSession: "retrieveSessionSecured",
    extractForm: "extractFormSecured",
    suggestions: "suggestionsSecured",
    retrieveAgentName: "retrieveAgentNameSecured",
    sessionsFacet: "sessionsFacetSecured",
    addMultiEntityValue: "addMultiEntityValueSecured",
    storeFormAuth: "storeFormAuthSecured",
    submitFormAuth: "submitFormAuthSecured",
    publishPushNotification: "publishPushNotificationSecured",
    getPushNotifications: "getPushNotificationsSecured",
    retrieveSnapshotDetails: "retrieveSnapshotDetailsSecured",
    evaluateEntityValueAuth: "evaluateEntityValueAuthSecured",
    retrieveAllEtValsDecrypted: "retrieveAllEtValsDecryptedSecured",
    deleteSolrSessions: "deleteSolrSessions"
  },
  modDD: {
    listRestrictedBehaviourDomains: "listRestrictedBehaviourDomainsSecured",
  }
};
