import { LinkedBehaviourIdsReq } from "@app/conversation-management/types/linked-behaviour-ids-req";
import { AuthenticationService } from "@app-core/services/authentication/authentication.service";
import { FetchBehaviourBody } from "@app/conversation-management/types/fetch-behaviour-body";
import { EndpointListService } from "@app/core/services/endpoint-list/endpoint-list.service";
import { BehaviourDetails } from "@app/conversation-management/types/behaviour-details";
import { TriggerListRequest } from "@app/core/misctypes/trigger-list-request";
import { ApiService } from "@app-core/services/api/api.service";
import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, of, ReplaySubject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class BehaviourManagementService {
  private url: string;
  public clientId: number;
  public param: HttpParams;
  private endPoint: string;
  public behaviourType: string;
  public dataForBehaviourDetail = new ReplaySubject<any>(0);
  public subscribedBhvList = new BehaviorSubject([]);
  public effectiveViewPolicies = new BehaviorSubject([]);
  public effectiveTriggerPolicies = new BehaviorSubject([]);

  constructor(
    private apiService: ApiService,
    private authService: AuthenticationService,
    private endpointListService: EndpointListService
  ) {
    this.param = new HttpParams();
    this.clientId = authService.client.id;
    this.param = this.param.set("clientId", `${this.clientId}`);
  }

  public getBehaviorListApiCall = (clientId: number, ownerType: string) => {
    this.endPoint = `/behaviourresponsemanagement/${this.endpointListService.getBehaviorListApiCall}?clientId=${clientId}&ownership=${ownerType}`;
    this.url = `${this.authService.client.api_gateway_url}${this.endPoint}`;
    return this.apiService.apicall(this.url, "", "GET_WITH_PARAM");
  }

  public deleteBehaviour = (params: HttpParams) => {
    this.endPoint = `/behaviourresponsemanagement/${this.endpointListService.deleteBehaviour}`;
    this.url = `${this.authService.client.api_gateway_url}${this.endPoint}`;
    return this.apiService.apicall(this.url, params, "GET_WITH_PARAM");
  }

  public disableApiCall(params) {
    this.endPoint = `/behaviourresponsemanagement/${this.endpointListService.disableApiCall}?behaviourId=${params.behaviourId}&status=${params.status}&behaviourType=${params.behaviourType}`;
    this.url = `${this.authService.client.api_gateway_url}${this.endPoint}`;
    return this.apiService.apicall(this.url, "", "GET_WITH_PARAM");
  }

  public copyApiCall = (behaviourId: string) => {
    this.endPoint = `/behaviourresponsemanagement/${this.endpointListService.copyApiCall}?behaviourId=${behaviourId}`;
    this.url = `${this.authService.client.api_gateway_url}${this.endPoint}`;
    return this.apiService.apicall(this.url, "", "GET_WITH_PARAM");
  }

  public createBehaviour = (data: BehaviourDetails) => {
    this.endPoint = `/behaviourresponsemanagement/${this.endpointListService.createBehaviour}`;
    this.url = `${this.authService.client.api_gateway_url}${this.endPoint}`;
    return this.apiService.apicall(this.url, data, "POST");
  }

  public makeBehaviourTemplate = (data: BehaviourDetails) => {
    this.endPoint = `/behaviourresponsemanagement/${this.endpointListService.makeBehaviourTemplate}`;
    this.url = `${this.authService.client.api_gateway_url}${this.endPoint}`;
    return this.apiService.apicall(this.url, data, "POST");
  }

  public uploadFile = (data: FormData) => {
    this.endPoint = `/asset-management/${this.endpointListService.uploadFile}`;
    this.url = `${this.authService.client.api_gateway_url}${this.endPoint}`;
    return this.apiService.apicall(this.url, data, "POST");
  }

  public getBehaviourDetail = (params: HttpParams) => {
    this.endPoint = `/behaviourresponsemanagement/${this.endpointListService.getBehaviourDetail}`;
    this.url = `${this.authService.client.api_gateway_url}${this.endPoint}`;
    return this.apiService.apicall(this.url, params, "GET_WITH_PARAM");
  }

  public getNlpEngines = (params: HttpParams) => {
    this.endPoint = `/nlpintentmanagement/${this.endpointListService.getNlpListApiCall}`;
    this.url = `${this.authService.client.api_gateway_url}${this.endPoint}`;
    return this.apiService.apicall(this.url, params, "GET_WITH_PARAM");
  }

  public getAllDomains = (params: HttpParams) => {
    this.endPoint = `/clientmanagement/${this.endpointListService.getBehaviourDomains}`;
    this.url = `${this.authService.client.api_gateway_url}${this.endPoint}`;
    return this.apiService.apicall(this.url, params, "GET_WITH_PARAM");
  }

  public listAllowedTriggers = (data: TriggerListRequest) => {
    const params: HttpParams = new HttpParams();
    this.endPoint = `/etmanagement/${this.endpointListService.listAllowedTriggers}`;
    this.url = `${this.authService.client.api_gateway_url}${this.endPoint}`;
    return this.apiService.apicall(this.url, data, "POST", params);
  }

  public getCurrencyList = (clientId?: number) => {
    const params: HttpParams = new HttpParams();
    const _clientId: number = clientId ? clientId : this.clientId;
    this.endPoint = `/clientmanagement/${this.endpointListService.getCurrencies}?clientId=${_clientId}`;
    this.url = `${this.authService.client.api_gateway_url}${this.endPoint}`;
    return this.apiService.apicall(this.url, params, "GET_WITH_PARAM");
  }

  public getLanguageList = (clientId?: number) => {
    const params: HttpParams = new HttpParams();
    const _clientId: number = clientId ? clientId : this.clientId;
    this.endPoint = `/clientmanagement/${this.endpointListService.getLanguages}?clientId=${_clientId}`;
    this.url = `${this.authService.client.api_gateway_url}${this.endPoint}`;
    return this.apiService.apicall(this.url, params, "GET_WITH_PARAM");
  }

  public getCountryCodeList = (clientId?: number) => {
    const params: HttpParams = new HttpParams();
    const _clientId: number = clientId ? clientId : this.clientId;
    this.endPoint = `/clientmanagement/${this.endpointListService.getCountryCodes}?clientId=${_clientId}`;
    this.url = `${this.authService.client.api_gateway_url}${this.endPoint}`;
    return this.apiService.apicall(this.url, params, "GET_WITH_PARAM");
  }

  public getLinkedBehaviours = (data: LinkedBehaviourIdsReq) => {
    this.endPoint = `/behaviourresponsemanagement/${this.endpointListService.linkedBehaviours}`;
    this.url = `${this.authService.client.api_gateway_url}${this.endPoint}`;
    return this.apiService.apicall(this.url, data, "POST");
  }

  public getDomains = (clientId: number) => {
    let params: HttpParams = new HttpParams();
    this.endPoint = `/clientmanagement/${this.endpointListService.getBehaviourDomains}?clientId=${clientId}`;
    this.url = `${this.authService.client.api_gateway_url}${this.endPoint}`;
    return this.apiService.apicall(this.url, params, "GET_WITH_PARAM");
  }

  public getDepartments = (clientId: number) => {
    let params: HttpParams = new HttpParams();
    this.endPoint = `/clientmanagement/${this.endpointListService.getDepartments}?clientId=${clientId}`;
    this.url = `${this.authService.client.api_gateway_url}${this.endPoint}`;
    return this.apiService.apicall(this.url, params, "GET_WITH_PARAM");
  }

  public getCountryCodeListMaster = () => {
    const params: HttpParams = new HttpParams();
    this.endPoint = `/clientmanagement/${this.endpointListService.getCountryCodes}`;
    this.url = `${this.authService.client.api_gateway_url}${this.endPoint}`;
    return this.apiService.apicall(this.url, params, "GET_WITH_PARAM");
  }

  public getCurrencyListMaster = () => {
    const params: HttpParams = new HttpParams();
    this.endPoint = `/clientmanagement/${this.endpointListService.getCurrencies}`;
    this.url = `${this.authService.client.api_gateway_url}${this.endPoint}`;
    return this.apiService.apicall(this.url, params, "GET_WITH_PARAM");
  }

  public getLanguageListMaster = () => {
    const params: HttpParams = new HttpParams();
    this.endPoint = `/clientmanagement/${this.endpointListService.getLanguages}`;
    this.url = `${this.authService.client.api_gateway_url}${this.endPoint}`;
    return this.apiService.apicall(this.url, params, "GET_WITH_PARAM");
  }

  public getClientMetaData = () => {
    const params: HttpParams = new HttpParams();
    this.endPoint = `/clientmanagement/${this.endpointListService.getClientMetaData}`;
    this.url = `${this.authService.client.api_gateway_url}${this.endPoint}`;
    return this.apiService.apicall(this.url, params, "GET_WITH_PARAM");
  }

  public getClientDataRetention = () => {
    const params: HttpParams = new HttpParams();
    this.endPoint = `/clientmanagement/${this.endpointListService.getClientDataRetention}`;
    this.url = `${this.authService.client.api_gateway_url}${this.endPoint}`;
    return this.apiService.apicall(this.url, params, "GET_WITH_PARAM");
  }

  public getClientDataRetentionMaster = (clientId) => {
    const params: HttpParams = new HttpParams();
    this.endPoint = `/clientmanagement/${this.endpointListService.getClientDataRetention}?clientId=${clientId}`;
    this.url = `${this.authService.client.api_gateway_url}${this.endPoint}`;
    return this.apiService.apicall(this.url, params, "GET_WITH_PARAM");
  }

  public getAllFromEmails = (clientId: number) => {
    let params: HttpParams = new HttpParams();
    params = params.set("clientId", `${clientId}`);
    this.endPoint = `/clientmanagement/${this.endpointListService.getAllFromEmails}`;
    this.url = `${this.authService.client.api_gateway_url}${this.endPoint}`;
    return this.apiService.apicall(this.url, params, "GET_WITH_PARAM");
  }

  public fetchAllBehaviours = (data: FetchBehaviourBody) => {
    this.endPoint = `/behaviourresponsemanagement/${this.endpointListService.fetchAllBehaviours}`;
    this.url = `${this.authService.client.api_gateway_url}${this.endPoint}`;
    return this.apiService.apicall(this.url, data, "POST");
  }

  public getSearchClients = (searchVal: string, searchBy: string) => {
    this.endPoint = `/clientmanagement/${this.endpointListService.searchClients}?searchKey=${searchVal}&searchBy=${searchBy}`;
    this.url = `${this.authService.client.api_gateway_url}${this.endPoint}`;
    return this.apiService.apicall(this.url, "", "GET");
  }

  public getTriggersOwnedByClient = (clientId: number) => {
    const params: HttpParams = new HttpParams();
    this.endPoint = `/etmanagement/${this.endpointListService.triggersOwnedByClient}?ownedBy=${clientId}`;
    this.url = `${this.authService.client.api_gateway_url}${this.endPoint}`;
    return this.apiService.apicall(this.url, params, "GET_WITH_PARAM");
  }

  public saveSEOFile = (data: FormData) => {
    this.endPoint = `/behaviourresponsemanagement/${this.endpointListService.saveSEOFile}`;
    this.url = `${this.authService.client.api_gateway_url}${this.endPoint}`;
    return this.apiService.apicall(this.url, data, "POST");
  }

  public updateSEOFile = (data: FormData) => {
    this.endPoint = `/behaviourresponsemanagement/${this.endpointListService.updateSEOFile}`;
    this.url = `${this.authService.client.api_gateway_url}${this.endPoint}`;
    return this.apiService.apicall(this.url, data, "POST");
  }

  public getSEOFileInfo = (data: any) => {
    this.endPoint = `/behaviourresponsemanagement/${this.endpointListService.getSEOFileInfo}`;
    this.url = `${this.authService.client.api_gateway_url}${this.endPoint}`;
    return this.apiService.apicall(this.url, data, "POST");
  }

  public publishSEOFile = (data: any) => {
    this.endPoint = `/behaviourresponsemanagement/${this.endpointListService.publishSEOFile}`;
    this.url = `${this.authService.client.api_gateway_url}${this.endPoint}`;
    return this.apiService.apicall(this.url, data, "POST");
  }

  public getAllTimezones = () => {
    this.endPoint = `/clientmanagement/${this.endpointListService.getAllTimezones}`;
    this.url = `${this.authService.client.api_gateway_url}${this.endPoint}`;
    return this.apiService.apicall(this.url, "", "GET");
  }

  public getDomainDirs = (desiredClientId: number, forDomain: string) => {
    let params: HttpParams = new HttpParams();
    this.endPoint = `/clientmanagement/${this.endpointListService.getClientDomainDirs}?desiredClientId=${desiredClientId}&forDomain=${forDomain}`;
    this.url = `${this.authService.client.api_gateway_url}${this.endPoint}`;
    return this.apiService.apicall(this.url, params, "GET_WITH_PARAM");
  }

  public getMultipageByDD = (domain: string) => {
    let params: HttpParams = new HttpParams();
    this.endPoint = `/behaviourresponsemanagement/${this.endpointListService.getMultiPageByDomainDirectory}?domain=${domain}`;
    this.url = `${this.authService.client.api_gateway_url}${this.endPoint}`;
    return this.apiService.apicall(this.url, params, "GET_WITH_PARAM");
  }

  public updateCommonPageDirs = (data: any) => {
    this.endPoint = `/behaviourresponsemanagement/${this.endpointListService.updateCommonPage}`;
    this.url = `${this.authService.client.api_gateway_url}${this.endPoint}`;
    return this.apiService.apicall(this.url, data, "POST");
  }
}
